import axios from 'axios'
import { getBackendURL_Supply_Market } from '../common/helper'

export default axios.create({
  // baseURL: 'https://do-supply-marketplace-dot-iprospect-global-data-lake.uc.r.appspot.com/',
  // baseURL: 'http://localhost:5002/',
  baseURL: getBackendURL_Supply_Market(),
  headers: {
    'Content-type': 'application/json',
    'ServiceConfig': JSON.stringify({ 'appCode': 'DOX', 'appEnv': sessionStorage?.getItem("referrer_env") })
  }
})
