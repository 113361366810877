import React, { useEffect, useState } from 'react'
import Penpal from 'penpal';
import '@dentsu-ui/components/styles.css'
import { Provider } from 'react-redux'
import ErrorBoundary from '../ErrorBoundary'
import AppLayout from '../AppLayout'
import { persistor, store } from '../../../redux/store'
import GlobalDialog from '../Shared/GlobalDialog/GlobalDialog'
import GlobalDialogProvider from '../Shared/GlobalDialog/GlobalDialogProvider'
import UserAuthenticationContextProvider from '../Shared/UserAuthenticationContext/UserAuthenticationContextProvider'
import { PersistGate } from 'redux-persist/integration/react'

const App = () => {
  // const [url, setUrl] = useState(false)

  // const setEnv = () => {
  //   if (!sessionStorage.getItem('referrer_env')) {
  //     const url = window.location.href;
  //     const referrer_env = url.includes("activationhub") ? "prod" : "int";
  //     console.log('INSIDE LOCATION ENV :: ---------- ', referrer_env);
  //     sessionStorage.setItem('referrer_env', referrer_env);
  //   }
  // }

  // useEffect(() => {
  //   const envs = ['int', 'stg', 'demo', 'prod', 'pch', 'hint'];
  //   const referrer = document.referrer;
  //   const matchedEnv = envs.find(env => referrer?.toLowerCase()?.includes('.' + env + '.'));
  //   console.log('App Referrer :: ---------- ', referrer, '; MATCHED ENV :: ', matchedEnv);
  //   if (matchedEnv && sessionStorage.getItem('referrer_env') !== matchedEnv) {
  //       console.log('INSIDE MATCHED ENV :: ---------- ', matchedEnv);
  //       sessionStorage.setItem('referrer_env', matchedEnv);
  //   } else {
  //     setEnv(); 
  //   }
  // }, []);


  var item_value = sessionStorage?.getItem("param");
  item_value && Penpal.connectToParent({});
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <UserAuthenticationContextProvider>
            <GlobalDialogProvider>
              <AppLayout />
              <GlobalDialog />
            </GlobalDialogProvider>
          </UserAuthenticationContextProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  )
}

export default App
