import axios from 'axios'
import { getBackendURL_Optimise } from '../common/helper'

export default axios.create({
  // baseURL: 'https://do-backend-dot-iprospect-global-data-lake.uc.r.appspot.com/',
  // baseURL: 'http://localhost:5000/',
  baseURL: getBackendURL_Optimise(),
  headers: {
    'Content-type': 'application/json',
    'ServiceConfig': JSON.stringify({ 'appCode': 'DOX', 'appEnv': sessionStorage?.getItem("referrer_env") })
  }
})
