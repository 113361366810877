import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { prepareUserDetails } from './helper'
import UserAuthenticationContext from './UserAuthenticationContext'
import { getOptimizationType, getPartners } from '../../../../../src/redux/features/campaign-optimization/campaignOptimizationSlice'
import { getPartnersData } from '../../../../common/helper'
import {
  getAllCards,
  getSelectedDentsuUser,
  updateUserDetails
} from '../../../../redux/features/user-management/userManagementSlice'
import { processAllCardsData } from '../../UserManagement/helper'
import { useLocation } from 'react-router-dom'

const UserAuthenticationContextProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState({})
  const [loadingUser, setLoadingUser] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()

  const callIfAdmin = async (result, authState) => {
    let processedGovCardsData;
    let processedCampaignCardsData;
    let processedTargetingCardsData;
    let processedMeasurementCardsData;
    let optimisation_types;
    await dispatch(getAllCards({
      // oktaUserId: authState?.idToken?.claims.sub,
      oktaUserId: result?.okta_user_id,
      accessToken: authState?.accessToken?.accessToken
    })).then(async response => {
      const data = response?.payload?.data;
      const govData = data?.filter((cards) => {
        if (cards?.page === "governance") {
          return cards
        }
      })
      const campaignData = data?.filter((cards) => {
        if (cards?.page === "campaign_setup") {
          return cards
        }
      })
      const targetingData = data?.filter((cards) => {
        if (cards?.page === "optimisation") {
          return cards
        }
      })
      const measurementData = data?.filter((cards) => {
        if (cards?.page === "measurement") {
          return cards
        }
      })

      processedGovCardsData = processAllCardsData(
        govData
      )
      processedCampaignCardsData = processAllCardsData(
        campaignData
      )
      processedTargetingCardsData = processAllCardsData(
        targetingData
      )
      processedMeasurementCardsData = processAllCardsData(
        measurementData
      )

    })
    await dispatch(
      getOptimizationType({
        // oktaUserId: authState?.idToken?.claims.sub,
        oktaUserId: result?.okta_user_id,
        accessToken: authState?.accessToken?.accessToken
      })
    ).then(async response => {
      optimisation_types = response?.payload
    })
    await dispatch(
      getPartners({
        // oktaUserId: authState?.idToken?.claims.sub,
        oktaUserId: result?.okta_user_id,
        accessToken: authState?.accessToken?.accessToken
      })
    ).then(async response => {
      const partnerResult = getPartnersData(response?.payload?.partners)
      const finalData = {
        req_data: {
          role: result?.role,
          okta_user_id: authState?.idToken?.claims.sub,
          // okta_user_id: result?.okta_user_id,
          name: result.name,
          email: result.email,
          status: result.status,
          partners: partnerResult,
          optimisation_type: optimisation_types,
          governance_cards: processedGovCardsData,
          measurement_cards: processedMeasurementCardsData,
          campaign_setup_cards: processedCampaignCardsData,
          optimisation_cards: processedTargetingCardsData,
          api_permissions: ['deal_management', 'supply_management']
        }
      }
      dispatch(
        updateUserDetails({
          data: finalData,
          // oktaUserId: authState?.idToken?.claims.sub,
          oktaUserId: result?.okta_user_id,
          accessToken: authState?.accessToken?.accessToken
        })
      )
      setLoadingUser(false)
      const updatedResult = result
      updatedResult.partner = []
      updatedResult.optimisation_type = []
      updatedResult['partner'] = partnerResult
      updatedResult['optimisation_type'] = optimisation_types
      setUserDetails(prepareUserDetails(updatedResult))
    })
  }

  const authenticateUser = authState => {
    if (isEmpty(userDetails) && authState?.isAuthenticated) {
      setLoadingUser(true)
      dispatch(
        getSelectedDentsuUser({
          oktaUserId: authState?.idToken?.claims.sub,
          accessToken: authState?.accessToken?.accessToken
        })
      )?.then(async res => {
        if (!isEmpty(res.payload)) {
          const result = res.payload
          setUserDetails(prepareUserDetails(result))
          result.role !== 'admin' && setLoadingUser(false)
          if (result.role === 'admin' && (location?.pathname !== '/marketplace' && location?.pathname !== '/new-supply-package' && location?.pathname !== '/view-deal' && location?.pathname !== '/inventory-list' && location?.pathname !== '/view-inventory-list' && location?.pathname !== '/new-inventory-list' && location?.pathname !== '/datasources')) {
            await callIfAdmin(result, authState)
          } else {
            setLoadingUser(false)
          }
        } else {
          setUserDetails()
          setLoadingUser(false)
        }
      })
    }
  }

  const value = useMemo(
    () => ({
      authenticateUser: authenticateUser,
      userDetails: userDetails,
      loadingUser
    }),
    [userDetails, loadingUser]
  )

  return (
    <UserAuthenticationContext.Provider value={value}>
      {children}
    </UserAuthenticationContext.Provider>
  )
}

UserAuthenticationContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default UserAuthenticationContextProvider
