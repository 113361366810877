export const processOktaUserList = users => {
  if (!users || users.length < 1) return []

  return users.map(user => {
    // return {
    //   value: user.id,
    //   label: user.profile.name,
    //   email: user.profile.email,
    //   oktaId: user.id
    // }
    return {
      value: user.oktaId,
      label: user.firstName + ' ' + user.lastName,
      email: user.email,
      oktaId: user.oktaId
    }
  })
}
